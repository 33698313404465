import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/help.module.scss";
import { Link } from "gatsby-plugin-intl";
import "../assets/css/helpAccordion.scss";

const CookiesPage = () => (
	<Layout>
		<Seo title="Cookies" />
		<section className={styles.help}>
			<article className={styles.bgHelp}>
				<div className={styles.helpWrapper}>
					<Link to="/" className="lineLink">
						<u>Volver a la home</u>
					</Link>
				</div>
				<div className={styles.titleWrapper}>
					<h2>Cookies</h2>
				</div>
			</article>

			<article className={styles.bgText}>
				<div className={styles.text}>
					<h3>¿Qué son las cookies?</h3>
					<p>
						Una cookie es un fichero que se descarga en su ordenador al
						acceder a determinadas páginas web. Las cookies permiten a una
						página web, entre otras cosas, almacenar y recuperar
						información sobre hábitos de navegación de un usuario o de su
						equipo y, dependiendo de la información que contengan y de la
						forma que utilice su equipo, pueden utilizarse para reconocer
						al usuario.
						<br />
						<b>Tipos de cookies que utiliza esta web</b>
						<br />
						Esta página web utiliza las cookies para una serie de
						finalidades, incluidas:
					</p>
					<ul>
						<li>
							<b>Cookies de análisis:</b> Son aquellas que son tratadas
							por nosotros o por terceros, nos permiten cuantificar el
							número de usuarios y así realizar la medición y análisis
							estadístico de la utilización que hacen los usuarios del
							servicio ofertado. Para ello se analiza la navegación en
							nuestra página web con el fin de mejorar la misma.
							<br />
							La aplicación que utilizamos para obtener y analizar la
							información es <b>Google Analytics</b>. Las cookies
							utilizadas para esto (_utma, _utmb, _utmc, _utmt y _utmz)
							son cookies de Terceros. Estas cookies se utilizan para
							recoger información sobre la navegación de los usuarios por
							el sitio con el fin de conocer el origen de las visitas y
							otros datos similares a nivel estadístico. A través de los
							siguientes enlaces puede ver el detalle de las cookies que
							utiliza Google Analitiycs y su propósito:
							<br />
							<a
								href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
								target="_blank"
								rel="noreferrer"
								class="lineLink white"
							>
								https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
							</a>
							y Centro de privacidad de Google.
							<br />
							<br />
							Nota: Las cookies de tipo ‘Propias’ son utilizadas sólo por
							el propietario de esta web y las cookies ‘De terceros’ son
							utilizadas, por el prestador del servicio que está
							detallado en el cuadro anterior.
						</li>
					</ul>
					<h3>Eliminar o bloquear las cookies</h3>
					<p>
						Usted puede permitir, bloquear o eliminar las cookies
						instaladas en su equipo mediante la configuración de las
						opciones del navegador instalado en su ordenador.
						<br />
						Puede ver más información sobre su navegador y las cookies en
						los siguientes enlaces:
					</p>
					<ul>
						<li>
							<b>Firefox:</b>
							<a
								href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"
								class="lineLink white"
								target="_blank"
								rel="noreferrer"
							>
								http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
							</a>
						</li>
						<li>
							<b>Chrome:</b>
							<a
								href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647"
								class="lineLink white"
								target="_blank"
								rel="noreferrer"
							>
								http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
							</a>
						</li>
						<li>
							<b>Internet Explorer:</b>
							<a
								href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in"
								class="lineLink white"
								target="_blank"
								rel="noreferrer"
							>
								http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in
							</a>
						</li>
						<li>
							<b>Safari:</b>{" "}
							<a
								href="http://support.apple.com/kb/ph5042"
								class="lineLink white"
								target="_blank"
								rel="noreferrer"
							>
								http://support.apple.com/kb/ph5042
							</a>
						</li>
						<li>
							<b>Opera:</b>
							<a
								href="http://help.opera.com/Windows/11.50/es-ES/cookies.html"
								class="lineLink white"
								target="_blank"
								rel="noreferrer"
							>
								http://help.opera.com/Windows/11.50/es-ES/cookies.html
							</a>
						</li>
					</ul>
					<h3>Otros navegadores</h3>
					<p>
						Consulte la documentación del navegador que tenga instalado.
						<br />
						Complemento de inhabilitación para navegadores de{" "}
						<b>Google Analytics</b>
						<br />
						Si desea rechazar las cookies analíticas de Google Analytics
						en todos los navegadores, de forma que no se envíe información
						suya a Google Analytics, puede descargar un complemento que
						realiza esta función desde este enlace:
						https://tools.google.com/dlpage/gaoptout.
					</p>
					<h3>Publicidad</h3>
					<p>
						Este sitio también puede albergar publicidad propia, de
						afiliados o de redes publicitarias. Esta publicidad se muestra
						mediante servidores publicitarios que también utilizan cookies
						para mostrar contenidos publicitarios afines a los usuarios.
						<br />
						Cada uno de estos servidores dispone de su propia política de
						privacidad, que puede ser consultada en sus propias páginas
						web.
						<br />
						Actualmente este sitio alberga publicidad de:
					</p>
					<ul>
						<li>
							<b>Google Adsense</b> – Política de privacidad | Términos y
							condiciones | Políticas de privacidad
						</li>
					</ul>
				</div>
			</article>
		</section>
	</Layout>
);

export default CookiesPage;
